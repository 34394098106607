import React from 'react'
import * as productCodes from '@/constants/product-codes'

import styles from './ProductAccordion.module.scss'

const accordionData = {
    [`${productCodes.LOOM_AND_LEAF}`]: [
        {
            title: 'Eco-friendly foams',
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>We substitute 30% of the polyurethane in our foams with renewable, naturally-derived soybean & corn oils</li>
                    <li>The manufacturing of our foams produce less greenhouse gasses than traditional polyurethane foam</li>
                </ul>
            )
        },
        {
            title: 'Certified free of harmful chemicals',
            content: (
                <ul>
                    <li>CertiPUR-US® certified free of ozone depleters, PBDEs, TDCPP, and TCEP flame retardants, mercury, lead, and other heavy metals, formaldehyde, and phthalates</li>
                    <li>Low volatile organic compounds (VOCs) means no off-gassing fumes when your mattress arrives, so you can breathe easy</li>
                </ul>
            )
        },
        {
            title: 'Lower carbon footprint',
            content: (
                <ul>
                    <li>Each mattress is made fresh to order in one of our 19 U.S. factory partners</li>
                    <li>Your mattress travels less than a 100 miles on average from our factory to your home</li>
                </ul>
            )
        }],
    [`${productCodes.SAATVA_CONTOUR5}`]: [
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>1</span>
                    <span>Patented Lumbar Zone® PCM Matrix</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Strategically positioned at the center third of the mattress, where most of your body weight is focused, to regulate temperature, absorb and disperse excess body heat, and deliver enhanced lower back support.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>2</span>
                    <span>Gel-infused high-density 4lb memory foam</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Body-contouring comfort meets ultimate cooling with our gel infusion and ventilated airflow channels throughout the 1.5” memory foam to help you stay at optimal temperature for restful sleep.</li>
                </ul>
            )
        }],
    [`${productCodes.ZENHAVEN}`]: [
        {
            title: 'Zoned firmness',
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>5 ergonomic zones designed to give you enhanced lumbar support in the center third of the mattress</li>
                    <li>Part of our Lumbar Zone® Technology, endorsed by the Congress of Chiropractic State Association</li>
                </ul>
            )
        },
        {
            title: 'Durability',
            content: (
                <ul>
                    <li>Natural latex is the most durable mattress foam type on the market</li>
                    <li>Outlasts polyurethane foams and other synthetic foams</li>
                </ul>
            )
        },
        {
            title: 'Excellent pressure relief',
            content: (
                <ul>
                    <li>Relieves pressure points for better sleep and less back and joint pain</li>
                    <li>Provides a unique buoyant feel and responsive support</li>
                </ul>
            )
        },
        {
            title: 'Superb breathability',
            content: (
                <ul>
                    <li>Natural latex is more breathable than synthetic foams</li>
                    <li>Vented airflow channels add even more breathability</li>
                </ul>
            )
        }
    ],
    [`${productCodes.SCENTED_VOTIVES}`]: [
        {
            title: 'Unwind',
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Ylang Ylang + Neroli</li>
                    <li>Calms the mind for peaceful bliss</li>
                </ul>
            )
        },
        {
            title: 'Drift',
            content: (
                <ul>
                    <li>Lavender + Vetiver</li>
                    <li>Eases tension and invites relaxation</li>
                </ul>
            )
        },
        {
            title: 'Dream',
            content: (
                <ul>
                    <li>Cedarwood + Vanilla</li>
                    <li>Grounds the senses for restful sleep</li>
                </ul>
            )
        },
        {
            title: 'Restore',
            content: (
                <ul>
                    <li>Chamomile + Bergamot</li>
                    <li>Soothes the mind and body</li>
                </ul>
            )
        }
    ],
    [productCodes.WEIGHTED_SILK_EYE_MASK]: [
        {
            title: 'It signals your brain that it’s time for bed',
            content: (
                <ul>
                    <li>Exposure to artificial light at night can mess with your body’s circadian rhythms, which regulate the sleep-wake cycle. Blocking out disruptive blue light and ambient light helps your mind and body wind down to prepare for sleep.</li>
                    <li>How does blue light mess with your sleep? <a href='https://www.saatva.com/blog/blue-light-sleep/' target='_blank' className='t-link'>Learn more on our blog</a>.</li>
                </ul>
            )
        },
        {
            title: 'It helps you fall asleep faster',
            content: (
                <ul>
                    <li>The gentle deep pressure stimulation from a weighted eye mask can help induce feelings of calm, comfort, and relaxation.</li>
                    <li>A dark environment also helps your body produce more of the sleep-inducing hormone melatonin.</li>
                    <li>How can a weighted eye mask help you sleep better? <a href='https://saatva.com/blog/weighted-eye-mask-for-sleeping/?internal=true' target='_blank' className='t-link'>Learn more on our blog</a>.</li>
                </ul>
            )
        },
        {
            title: 'It can help soothe tired or dry eyes and ease tension headaches',
            content: (
                <ul>
                    <li>Anecdotal evidence suggests that a weighted eye mask can offer soothing tension relief around the eye area.</li>
                </ul>
            )
        }
    ],
    [productCodes.SILK_EYE_MASK]: [
        {
            title: 'It signals your brain that it’s time for bed',
            content: (
                <ul>
                    <li>Exposure to artificial light at night can mess with your body’s circadian rhythms, which regulate the sleep-wake cycle. Blocking out disruptive blue light and ambient light helps your mind and body wind down to prepare for sleep.</li>
                    <li>How does blue light mess with your sleep? <a href='https://www.saatva.com/blog/blue-light-sleep/' target='_blank' className='t-link'>Learn more on our blog</a>.</li>
                </ul>
            )
        },
        {
            title: 'It helps you fall asleep faster',
            content: (
                <ul>
                    <li>A dark environment helps your body produce more of the sleep-inducing hormone melatonin.</li>
                </ul>
            )
        },
        {
            title: 'It helps you stay asleep longer',
            content: (
                <ul>
                    <li>Exposure to light can disrupt your sleep. Eye masks can help you get more deep, restorative sleep with less disruptions.</li>
                    <li>Eye masks are also great for sleeping in during the day, catching some zzs on the plane, and sleeping in bright rooms without blackout curtains.</li>
                    <li>Curious about the science behind sleep masks? <a href='https://www.saatva.com/blog/do-sleep-masks-help-you-sleep/' target='_blank' className='t-link'>Learn more in our blog</a>.</li>
                </ul>
            )
        }
    ],
    [`${productCodes.SCENTED_CANDLES}`]: [
        {
            title: 'Unwind',
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Ylang Ylang + Neroli</li>
                    <li>Calms the mind for peaceful bliss</li>
                </ul>
            )
        },
        {
            title: 'Drift',
            content: (
                <ul>
                    <li>Lavender + Vetiver</li>
                    <li>Eases tension and invites relaxation</li>
                </ul>
            )
        },
        {
            title: 'Dream',
            content: (
                <ul>
                    <li>Cedarwood + Vanilla</li>
                    <li>Grounds the senses for restful sleep</li>
                </ul>
            )
        },
        {
            title: 'Restore',
            content: (
                <ul>
                    <li>Chamomile + Bergamot</li>
                    <li>Soothes the mind and body</li>
                </ul>
            )
        }
    ],
    [`${productCodes.SAATVA_LATEX_HYBRID}`]: [
        {
            title: 'Zoned firmness',
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>5 ergonomic zones designed to give you enhanced lumbar support in the center third of the mattress</li>
                    <li>Part of our Lumbar Zone® Technology, endorsed by the Congress of Chiropractic State Association</li>
                </ul>
            )
        },
        {
            title: 'Durability',
            content: (
                <ul>
                    <li>Natural latex is the most durable mattress foam type on the market</li>
                    <li>Outlasts polyurethane foams and other synthetic foams</li>
                </ul>
            )
        },
        {
            title: 'Excellent pressure relief',
            content: (
                <ul>
                    <li>Relieves pressure points for better sleep and less back and joint pain</li>
                    <li>Provides a unique buoyant feel and responsive support</li>
                </ul>
            )
        },
        {
            title: 'Superb breathability',
            content: (
                <ul>
                    <li>Natural latex is more breathable than synthetic foams</li>
                    <li>Vented airflow channels add even more breathability</li>
                </ul>
            )
        }
    ],
    [`${productCodes.CRIB_MATTRESS}`]: [
        {
            title: 'Durability',
            content: (
                <ul>
                    <li>Natural latex is the most durable mattress foam type on the market</li>
                    <li>Outlasts polyurethane foams and other synthetic foams</li>
                </ul>
            )
        },
        {
            title: 'Excellent pressure relief',
            content: (
                <ul>
                    <li>Relieves pressure points for better sleep and less back and joint pain</li>
                    <li>Provides a unique buoyant feel and responsive support</li>
                </ul>
            )
        },
        {
            title: 'Superb breathability',
            content: (
                <ul>
                    <li>Natural latex is more breathable than synthetic foams</li>
                    <li>Vented airflow channels add even more breathability</li>
                </ul>
            )
        }
    ],
    [`${productCodes.SAATVA_HD}`]: [
        {
            title: 'Zoned firmness',
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>5 ergonomic zones designed to give you enhanced lumbar support in the center third of the mattress</li>
                    <li>Part of our Lumbar Zone® Technology, endorsed by the Congress of Chiropractic State Association</li>
                </ul>
            )
        },
        {
            title: 'Durability',
            content: (
                <ul>
                    <li>Natural latex is the most durable mattress foam type on the market</li>
                    <li>Outlasts polyurethane foams and other synthetic foams</li>
                </ul>
            )
        },
        {
            title: 'Excellent pressure relief',
            content: (
                <ul>
                    <li>Relieves pressure points for better sleep and less back and joint pain</li>
                    <li>Provides a unique buoyant feel and responsive support</li>
                </ul>
            )
        },
        {
            title: 'Superb breathability',
            content: (
                <ul>
                    <li>Natural latex is more breathable than synthetic foams</li>
                    <li>Vented airflow channels add even more breathability</li>
                </ul>
            )
        }
    ],
    ['why-is-linen-worth-the-splurge']: [
        {
            title: 'Linen is exceptionally breathable',
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Because of its long fibers, linen allows great airflow and naturally wicks away moisture to keep you cooler in warm weather</li>
                    <li>It also keeps you warm in colder weather, making it a great bedding choice year round</li>
                </ul>
            )
        },
        {
            title: 'Linen is exceptionally breathable',
            content: (
                <ul>
                    <li>It’s considered to be the most durable natural fiber in the world</li>
                    <li>With proper care, you can enjoy your linen bedding for decades to come</li>
                </ul>
            )
        },
        {
            title: 'Linen gets better (and softer) with time',
            content: (
                <ul>
                    <li>It gets softer and more luxurious with every wash</li>
                </ul>
            )
        },
        {
            title: 'Linen is healthier for you',
            content: (
                <ul>
                    <li>It has excellent moisture-wicking properties</li>
                    <li>It’s naturally hypoallergenic and resistant to dust mites, microbes, and other common indoor allergens</li>
                    <li>Our linen is also OEKO-TEX® Standard 100 certified to be free of potentially harmful chemicals.</li>
                </ul>
            )
        },
        {
            title: 'Linen is healthier for the planet',
            content: (
                <ul>
                    <li>It’s from the fibers of the hardy flax plant which requires less water and energy to grow than traditionally grown cotton</li>
                    <li>It’s also biodegradable, making it a very eco-friendly fabric</li>
                </ul>
            )
        }
    ],
    [`${productCodes.THE_SAATVA_PILLOW}--why-natural`]: [
        {
            title: 'Durability',
            content: (
                <ul>
                    <li>Natural latex is the most durable mattress foam type on the market</li>
                    <li>Outlasts polyurethane foams and other synthetic foams</li>
                </ul>
            )
        },
        {
            title: 'Excellent pressure relief',
            content: (
                <ul>
                    <li>Relieves pressure points for better sleep and less back and joint pain</li>
                    <li>Provides a unique buoyant feel and responsive support</li>
                </ul>
            )
        },
        {
            title: 'Superb breathability',
            content: (
                <ul>
                    <li>Natural latex is more breathable than synthetic foams</li>
                </ul>
            )
        }
    ],
    [`natural-latex`]: [
        {
            title: 'Durability',
            content: (
                <ul>
                    <li>Natural latex is the most durable mattress foam type on the market</li>
                    <li>Outlasts polyurethane foams and other synthetic foams</li>
                </ul>
            )
        },
        {
            title: 'Excellent pressure relief',
            content: (
                <ul>
                    <li>Relieves pressure points for better sleep and less back and joint pain</li>
                    <li>Provides a unique buoyant feel and responsive support</li>
                </ul>
            )
        },
        {
            title: 'Superb breathability',
            content: (
                <ul>
                    <li>Natural latex is more breathable than synthetic foams</li>
                    <li>Vented airflow channels add even more breathability</li>
                </ul>
            )
        }
    ],
    [`${productCodes.ORGANIC_QUILTED_PILLOW}`]: [
        {
            title: 'Durability',
            content: (
                <ul>
                    <li>Natural latex is the most durable mattress foam type on the market</li>
                    <li>Outlasts polyurethane foams and other synthetic foams</li>
                </ul>
            )
        },
        {
            title: 'Excellent pressure relief',
            content: (
                <ul>
                    <li>Relieves pressure points for better sleep and less back and joint pain</li>
                    <li>Provides a unique buoyant feel and responsive support</li>
                </ul>
            )
        },
        {
            title: 'Superb breathability',
            content: (
                <ul>
                    <li>Natural latex is more breathable than synthetic foams</li>
                    <li>Vented airflow channels add even more breathability</li>
                </ul>
            )
        }
    ],
    [`${productCodes.SCENTED_VOTIVES}`]: [
        {
            title: 'Unwind',
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Ylang Ylang + Neroli</li>
                    <li>Calms the mind for peaceful bliss</li>
                </ul>
            )
        },
        {
            title: 'Drift',
            content: (
                <ul>
                    <li>Lavender + Vetiver</li>
                    <li>Eases tension and invites relaxation</li>
                </ul>
            )
        },
        {
            title: 'Dream',
            content: (
                <ul>
                    <li>Cedarwood + Vanilla</li>
                    <li>Grounds the senses for restful sleep</li>
                </ul>
            )
        },
        {
            title: 'Restore',
            content: (
                <ul>
                    <li>Chamomile + Bergamot</li>
                    <li>Soothes the mind and body</li>
                </ul>
            )
        }
    ],
    [`${productCodes.SOLAIRE}`]: [
        {
            title: 'Left/Right',
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Press center LEFT/RIGHT button to select a side to adjust, LCD screen will show “L” or “R” accordingly</li>
                </ul>
            )
        },
        {
            title: 'Firm',
            content: (
                <ul>
                    <li>Increases the air chamber firmness</li>
                    <li>Press FIRM button once to inflate, press any button to stop inflation ( auto stops when it shows the max of 50 on the LCD screen)</li>
                </ul>
            )
        },
        {
            title: 'Soft',
            content: (
                <ul>
                    <li>Decreases the air chamber firmness</li>
                    <li>While lying in bed, press SOFT button once to deflate, press any button to stop deflation (auto stops when it shows the minimum of 0 on the LCD screen)</li>
                </ul>
            )
        },
        {
            title: 'Memory',
            content: (
                <ul>
                    <li><strong>To set memory level:</strong> when the mattress is in your desired firmness level, press and hold MEM1 button for 3 seconds until “MEM 1” flashes on screen to save as a new memory level; repeat for MEM2</li>
                    <li><strong>To select a memory level:</strong> while lying in bed, select the side of the bed then press MEM1 or MEM2 button</li>
                </ul>
            )
        }
    ],
    [`${productCodes.SAATVA_RX}`]: [
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>1</span>
                    <span>Rx Wave-Tech® Foam Layer</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Groundbreaking material that works with the micro-coil layer above to contour and respond to your body to <strong>instantly relieve pressure points</strong></li>
                    <li>Our proprietary foam is infused with graphite and phase-change material (PCM) to help <strong>regulate body temperature</strong>, keeping you cool and comfortable all night.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>2</span>
                    <span>8" Comfort Coil Unit</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Triple-tempered recycled steel springs provide <strong>maximum contouring and pressure relief for sleepers with serious chronic back & joint issues.</strong></li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>3</span>
                    <span>Quantum-Edge Coils</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Firmer caliper spring coils along the left and right rails to offer <strong>excellent edge support for better mobility</strong> while getting in and out of bed.</li>
                </ul>
            )
        }
    ],
    [`${productCodes.DOG_BED}`]: [
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>1</span>
                    <span>Spill & stain repellent, high-performance fabric cover</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Removable & durable cover repels liquids & stains for easy cleanup, while staying breathable</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>2</span>
                    <span>Plush comfort fibers</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Pillow top layer is baffle quilted inside for even distribution of cuddle-worthy softness</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>3</span>
                    <span>Micro-coil support core</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Offers buoyant, responsive support to keep your pet’s spine in healthy alignment and adds to the bed’s breathability</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>4</span>
                    <span>Non-slip base</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Helps prevent the bed from slipping and sliding around</li>
                </ul>
            )
        }
    ],
    [`${productCodes.MEMORY_FOAM_HYBRID}`]: [
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>1</span>
                    <span>AirCradle™ memory foam</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Responds to your body’s movements and pressure</li>
                    <li>Circulates airflow through the foam’s unique wave design</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>2</span>
                    <span>Cooling gel-infused memory foam lumbar crown</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Draws excess heat away from the sleep surface like a magnet</li>
                    <li>Prevents overheating so you stay asleep longer</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>3</span>
                    <span>Individually wrapped comfort coils</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Maintains great airflow throughout the mattress</li>
                    <li>Disperses body heat so you sleep comfortably all night</li>
                </ul>
            )
        }
    ],
    'higher-quality-leather': [
        {
            title: 'Sustainably sourced',
            content: (
                <ul>
                    <li>Sustainably sourced Hides used are the by-products of free range, ethically-raised livestock.</li>
                </ul>
            )
        },
        {
            title: 'Eco-friendly processing',
            content: (
                <ul>
                    <li>Water used in processing is collected, treated, and recycled to conserve natural resources.</li>
                    <li>All organic leather waste is repurposed as fertilizer for agricultural use.</li>
                </ul>
            )
        },
        {
            title: 'REACH & Prop 65 compliant',
            content: (
                <ul>
                    <li>All chemicals used in the processing of our leathers are registered with EU REACH to ensure all substances meet their strict restrictions of human health and environmental safety.</li>
                    <li>Our leathers are compliant with Prop 65, notifying California residents of any significant amounts of listed chemicals present in any part of the manufacturing process.</li>
                </ul>
            )
        }
    ],
    [`${productCodes.CLASSIC_STARTER_BUNDLE}`]: [
        {
            title: 'Starting from scratch',
            isOpenOnRender: true,
            content: (
                <p>Whether you’re redoing a room in your home or moving to a new one, this bundle gives you the essential building blocks for lasting comfort.</p>
            )
        },
        {
            title: 'Creating an inviting space',
            content: (
                <p>If you’re looking to upgrade your guest room or bedroom, this bundle is the perfect start to create a comfy retreat.</p>
            )
        },
        {
            title: 'Upgrade your sleep setup',
            content: (
                <p>Congrats! You’re ready to upgrade to the luxurious sleep you deserve. You can’t go wrong with these tried-and-true staples as your introduction to Saatva.</p>
            )
        }
    ],
    [`${productCodes.THE_SAATVA_PILLOW}`]: [
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>1</span>
                    <span>Machine washable pillow cover</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Soft, breathable cover made with organic cotton wicks away moisture for cooler sleep. Gusseted sides with contrast welting keeps the shape of the pillow. Unzip to machine wash and tumble dry low or hang to dry.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>2</span>
                    <span>Breathable & plush fill layer</span>
                </span>
            ),
            content: (
                <ul>
                    <li>The fill layer is made up of certified organic cotton filled with plush, down-like microdenier fibers. It's hypoallergenic and breathable to give you cooler, more restful sleep.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>3</span>
                    <span>Hypoallergenic shredded natural latex core</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Recommended by chiropractors and orthopedists for ultimate head and neck support, natural latex is responsive, hypoallergenic, dust mite and mildew resistant, and more breathable than synthetic foams.</li>
                </ul>
            )
        }],
    [`${productCodes.ORGANIC_QUILTED_PILLOW}`]: [
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>1</span>
                    <span>Machine-washable quilted cotton knit cover</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>GOTS® certified organic cotton knit is quilted with an organic New Zealand wool fill to create the plushest, most breathable sleep surface.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>2</span>
                    <span>Hypoallergenic shredded natural latex core</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Recommended by chiropractors and orthopedists for ultimate head and neck support, natural latex is responsive, hypoallergenic, dust mite and mildew resistant, and more breathable than synthetic foams.</li>
                </ul>
            )
        }],
    [`${productCodes.MEMORY_FOAM_PILLOW}`]: [
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>1</span>
                    <span>Machine-washable knit cover</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Organic cotton blend is soft, breathable, and wicks away moisture for cooler sleep.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>2</span>
                    <span>Graphite-infused memory foam</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Advanced graphite technology draws heat away to keep you cooler as the memory foam contours to your head and neck for responsive support.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>2</span>
                    <span>Shredded memory foam & latex core</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Our proprietary blend of shredded memory foams provides optimal head and neck support while buoyant latex provides increased airflow and durability.</li>
                </ul>
            )
        }],
    [`${productCodes.DOWN_ALTERNATIVE_PILLOW}`]: [
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>1</span>
                    <span>Breathable organic cotton cover</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>100% organic cotton is soft, breathable, and finished with an elegant satin piping</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>2</span>
                    <span>Lofty microdenier down alternative</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Hypoallergenic down alternative fibers create micro air-pockets for a plush, silky-soft feel.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>2</span>
                    <span>Supportive memory fiber clusters</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Spring-like down alternative fibers offer greater support and durability than traditional fiber fill.</li>
                </ul>
            )
        }],
    [`${productCodes.CLOUD_MEMORY_FOAM_PILLOW}`]: [
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>1</span>
                    <span>Machine-washable knit cover</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Organic cotton blend is soft, breathable, and wicks away moisture for cooler sleep.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>2</span>
                    <span>Shredded gel-infused memory foam & latex fill</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Our proprietary blend of shredded CertiPUR-US® certified memory foam provides optimal head & neck support, while buoyant latex foam offers superb breathability, durability and keeps the pillow loft from falling flat.</li>
                </ul>
            )
        }],
    [`${productCodes.VELVET_PICK_STICH_QUILT}`]: [
        {
            title: (
                <span>
                    <span>Use it on its own on warmer nights</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul><li>Place the velvet quilt as a layer over your sheets instead of your duvet.</li></ul>
            )
        },
        {
            title: (
                <span>
                    <span>Layer it with your duvet on chilly nights</span>
                </span>
            ),
            content: (
                <ul><li>If your feet tend to get cold, you can fold the quilt over your duvet at the foot of your bed for an extra layer of warmth. You can also place it under your duvet, hotel style. That way, you have the option of keeping your duvet folded over your legs or layer both over you on extra chilly nights.</li></ul>
            )
        },
        {
            title: (
                <span>
                    <span>Cozy up on the couch</span>
                </span>
            ),
            content: (
                <ul><li>Our velvet pick stitch quilt isn’t just for bed. You can easily take it to the couch as a cozy yet breathable blanket for movie nights or binge watching your favorite shows.</li></ul>
            )
        }
    ],
    ['worthTheSplurge']: [
        {
            title: (
                <span>
                    <span>Linen is exceptionally breathable</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Because of its long fibers, linen allows great airflow and naturally wicks away moisture to keep you cooler in warm weather</li>
                    <li>It also keeps you warm in colder weather, making it a great bedding choice year round</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Linen lasts for a long time</span>
                </span>
            ),
            content: (
                <ul>
                    <li>It’s considered to be the most durable natural fiber in the world</li>
                    <li>With proper care, you can enjoy your linen bedding for decades to come</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Linen gets better (and softer) with time</span>
                </span>
            ),
            content: (
                <ul>
                    <li>It gets softer and more luxurious with every wash</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Linen is healthier for you</span>
                </span>
            ),
            content: (
                <ul>
                    <li>It has excellent moisture-wicking properties</li>
                    <li>It’s naturally hypoallergenic and resistant to dust mites, microbes, and other common indoor allergens</li>
                    <li>Our linen is also OEKO-TEX® Standard 100 certified to have passed safety tests for the presence of harmful substances</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Linen is healthier for the planet</span>
                </span>
            ),
            content: (
                <ul>
                    <li>It’s from the fibers of the hardy flax plant which requires less water and energy to grow than traditionally grown cotton</li>
                    <li>It’s also biodegradable, making it a very eco-friendly fabric</li>
                </ul>
            )
        }
    ],
    [`${productCodes.ORGANIC_VELVET_QUILT}`]: [
        {
            title: (
                <span>
                    <span>Use it on its own on warmer nights</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>When you need a lighter layer over your sheets, use the velvet quilt instead of your duvet.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Layer it with your duvet on chilly nights</span>
                </span>
            ),
            content: (
                <ul>
                    <li>If your feet tend to get cold, you can fold the quilt over your duvet at the foot of your bed for an extra layer of warmth.</li>
                    <li>You can also place it under your duvet, hotel style. That way, you have the option of keeping your duvet folded over your legs or layer both over you on extra chilly nights.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Cozy up on the couch</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Our cotton velvet quilt isn’t just for bed. You can easily take it to the couch as a cozy yet lightweight blanket for movie nights or binge watching your favorite shows.</li>
                </ul>
            )
        }],
    [`${productCodes.ORGANIC_COTTON_CHANNEL_QUILT}`]: [
        {
            title: (
                <span>
                    <span>Use it on its own on warmer nights</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>When you just need a lightweight layer over your sheets, use the cotton quilt instead of your duvet. You can even use it in lieu of a top sheet.</li>
                    <li>Either option is perfect for warm summer nights when breathability is key. </li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Layer it with your duvet on chilly nights</span>
                </span>
            ),
            content: (
                <ul>
                    <li>If your feet tend to get cold, you can fold the quilt over your duvet at the foot of your bed for an extra layer of warmth.</li>
                    <li>You can also place it under your duvet, hotel style. That way, you have the option of keeping your duvet folded over your legs or layer both over you on extra chilly nights.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Cozy up on the couch</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Our cotton quilt isn’t just for bed. Because it’s so lightweight, you can easily take it to the couch as a blanket for movie nights or binge watching your favorite shows.</li>
                </ul>
            )
        }],
    [`${productCodes.AERO_QUILT}`]: [
        {
            title: (
                <span>
                    <span>Use it on its own on warmer nights</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>When you just need a lightweight layer over your sheets, use the lightweight quilt instead of your duvet. You can even use it in lieu of a top sheet.</li>
                    <li>Either option is perfect for warm summer nights when breathability is key.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Layer it with your duvet on chilly nights</span>
                </span>
            ),
            content: (
                <ul>
                    <li>If your feet tend to get cold, you can fold the quilt over your duvet at the foot of your bed for an extra layer of warmth.</li>
                    <li>You can also place it under your duvet, hotel style. That way, you have the option of keeping your duvet folded over your legs or layer both over you on extra chilly nights.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Cozy up on the couch</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Our quilt isn’t just for bed. Because it’s so lightweight, you can easily take it to the couch as a blanket for movie nights or binge watching your favorite shows.</li>
                </ul>
            )
        }],
    [`${productCodes.WEIGHTED_BLANKET}`]: [
        {
            title: (
                <span>
                    <span>Use as a coverlet</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Layer underneath your duvet cover for extra warmth and comfort in colder months.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Use as a top layer</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Create a high-end layered bed look. Fold over the foot of your bed as a finishing accent or layer over your entire comforter or duvet for extra warmth and weight.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Use it on it's own</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Snuggle up with your weighted blanket in bed or on the couch whenever you need extra calming and relaxation.</li>
                </ul>
            )
        }],
    [`${productCodes.WAFFLE_KNIT_BLANKET}`]: [
        {
            title: (
                <span>
                    <span>Use as a coverlet</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Layer underneath your duvet cover for extra warmth in colder months.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Use as a top layer</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Create a high-end layered bed look. Fold over the foot of your bed as a finishing accent or layer over your entire comforter or duvet for extra warmth.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Use it on it's own</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Layer over your top sheet instead of your thicker comforter or duvet in warmer months.</li>
                </ul>
            )
        }],
    [`${productCodes.SWEATER_KNIT_BLANKET}`]: [
        {
            title: (
                <span>
                    <span>Use as a coverlet</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Layer underneath your duvet cover for extra warmth in colder months.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Use as a top layer</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Create a high-end layered bed look. Fold over the foot of your bed as a finishing accent or layer over your entire comforter or duvet for extra warmth.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Use as a throw</span>
                </span>
            ),
            content: (
                <ul>
                    <li>The throw size is perfect for cuddling up on the couch or your favorite reading chair.</li>
                </ul>
            )
        }],
    [`${productCodes.HERRINGBONE_KNIT_BLANKET}`]: [
        {
            title: (
                <span>
                    <span>Use as a coverlet</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Layer underneath your duvet cover for extra warmth in colder months.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Use as a top layer</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Create a high-end layered bed look. Fold over the foot of your bed as a finishing accent or layer over your entire comforter or duvet for extra warmth.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Use as a comforter alternative</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Layer over your top sheet instead of your thicker comforter or duvet in warmer months.</li>
                </ul>
            )
        }],
    [`${productCodes.DIAMOND_KNIT_BLANKET}`]: [
        {
            title: (
                <span>
                    <span>Use as a coverlet</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Layer underneath your duvet cover for extra warmth in colder months.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Use as a top layer</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Create a high-end layered bed look. Fold over the foot of your bed as a finishing accent or layer over your entire comforter or duvet for extra warmth.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span>Use as a comforter alternative</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Layer over your top sheet instead of your thicker comforter or duvet in warmer months.</li>
                </ul>
            )
        }],
    [`${productCodes.SILK_PILLOWCASE}`]: [
        {
            title: (
                <span>It creates less friction against your skin & hair</span>
            ),
            isOpenOnRender: true,
            content: (
                <ul><li>The silky smooth surface doesn’t tug on skin and hair, helping to reduce the development of fine lines, sleep creases, and is gentler on hair than other fabrics.</li></ul>
            )
        },
        {
            title: (
                <span>It keeps more of your skincare products on your skin</span>
            ),
            content: (
                <ul><li>Because silk is less absorbent than cotton and creates less friction against skin, it helps your skincare products stay on your skin rather than transferring onto your pillowcase.</li></ul>
            )
        },
        {
            title: (
                <span>It’s naturally hypoallergenic and temperature regulating</span>
            ),
            content: (
                <ul><li>Those with skin sensitivities will love the luxurious upgrade to a breathable silk pillowcase.</li></ul>
            )
        }
    ],
}

export default accordionData
